import type { TeamFieldsFragment } from "~/types/api";
import { Fragment } from "react";

interface Props {
  project: TeamFieldsFragment;
}

export default function ProjectTeamCells({ project }: Props) {
  return (
    <Fragment>
      <td className="text-center">
        {project.pc?.fullname || project.pm?.fullname || ""}
      </td>
      <td className="text-center">{project.technicalLead?.fullname || ""}</td>
      <td className="text-center">
        {project.projectUsers
          .filter((pu) => pu.role === "Expert")
          .map((pu) => pu.user.fullname)
          .join("/")}
      </td>
      <td className="text-center">{project.pm?.fullname || ""}</td>
    </Fragment>
  );
}
